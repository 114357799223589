/* ! NOTES: Phone number validation rules are defined in
 * `./metadata.min.json`, it is customly generated to only
 * include currently supported languages using the following
 * npm script command: `libphonenumber-metadata-generator src/components/PhoneNumberInput/metadata.min.json --countries US,CA,CY,RU,EG,ZA,GR,NL,BE,FR,ES,HU,IT,IS,RO,CH,AT,GB,DK,SE,NO,PL,DE,PE,MX,AR,BR,CL,CO,MY,AU,ID,PH,NZ,SG,TH,JP,KR,VN,CN,TR,IN,PT,LU,LT,LV,IE,AL,FI,BG,EE,RS,SI,CZ,SK,UY,HK,TW,LB,KW,SA,OM,AE,IL,BH,QA --types=mobile`
 * When adding support for additional languages, this command
 * needs to be updated and re-run.
 * Documentation available at https://github.com/catamphetamine/libphonenumber-js#customizing-metadata
 */

/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import cx from 'classnames';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './PhoneNumberInput.scss';
import Flag from '../Flag';
import metadata from './metadata.min.json';
import { DEFAULT_COUNTRY_NAMES } from '../../constants/config';

const PhoneNumberInput = ({
  onChange,
  defaultCountry,
  label,
  showLabel,
  value,
  name,
  hasError,
  validationCallback,
  invalidPhoneNumberErrorMessage,
  ...props
}) => {
  let errorMessage = hasError.message || null;
  let isValid = true;
  const countries = Object.keys(props.labels);
  const defaultCountries = Object.keys(DEFAULT_COUNTRY_NAMES);

  if (value) {
    isValid = isPossiblePhoneNumber(value);
    if (!isValid) {
      errorMessage = invalidPhoneNumberErrorMessage;
    }
  }
  validationCallback(!isValid);

  const rootClass = cx(s.root, (hasError || !isValid) && s.hasError);
  const labelClass = cx(
    s.label,
    !showLabel && 'visually-hidden',
    props.required && s.required,
  );

  const createOptionOrder = () => {
    const optionOrder = [];
    for (let i = 0; i < defaultCountries.length; i += 1) {
      if (countries.find(country => country === defaultCountries[i])) {
        optionOrder.push(defaultCountries[i]);
      }
    }
    optionOrder.sort();
    return optionOrder.length && [...optionOrder, '...'];
  };

  return (
    <div className={rootClass}>
      <span className={labelClass}>{label}</span>
      <PhoneInput
        name={name}
        value={value}
        metadata={metadata}
        defaultCountry={defaultCountry}
        addInternationalOption={false}
        internationalIcon={() => null}
        onChange={phone => onChange(phone, name)}
        countryOptionsOrder={createOptionOrder()}
        flagComponent={Flag}
        countries={countries}
        fieldType="PhoneInput"
        validNumber={isValid ? 'true' : 'false'}
        {...props}
      />
      {(hasError || !isValid) && (
        <span className={s.error}>{errorMessage}</span>
      )}
    </div>
  );
};

PhoneNumberInput.propTypes = {
  labels: PropTypes.oneOfType([PropTypes.shape()]).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
  validationCallback: PropTypes.func,
  defaultCountry: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  invalidPhoneNumberErrorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]),
};

PhoneNumberInput.defaultProps = {
  value: null,
  showLabel: true,
  required: false,
  hasError: false,
  validationCallback: () => {},
  invalidPhoneNumberErrorMessage: 'invalid phone number',
};

const mapState = state => {
  const labels = Object.assign(
    {},
    ...state.config.countries.map(({ value, label }) => ({
      [value]: label,
    })),
  );
  return {
    labels,
    defaultCountry: state.intl.countryCode,
  };
};

export default compose(
  withStyles(s),
  connect(mapState, () => ({})),
)(PhoneNumberInput);
