import React from 'react';
import PropTypes from 'prop-types';

const Field = ({ input: Comp, ...rest }) => <Comp {...rest} />;

Field.propTypes = {
  input: PropTypes.oneOf([PropTypes.func, PropTypes.node]).isRequired,
};

export default Field;
